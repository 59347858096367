import React, { useState } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const [number, setNumber] = useState("")
  const [firstName, setFirstName] = useState("")
  const [secondName, setSecondName] = useState("")

  const onActivation = async () => {
    try {
      Toast.show({
        icon: "loading",
        content: t("加载中..."),
        duration: 0
      })
      await post("card/active", {
        cardNumber: number,
        firstName: secondName,
        lastName: firstName
      })
      Toast.clear()
      setTimeout(() => {
        history.push("/")
      }, 1000)
    } catch (error) {
      console.log({
        error
      })
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  return (
    <div className="activation-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("激活卡")}</p>
      <div className="session-div">
        <p className="name">{t("卡号")}</p>
        <Input placeholder={t("實體卡上的卡號")} value={number} className="input" onChange={value => setNumber(value)} />
      </div>
      <div className="session-div">
        <p className="name">{t("姓氏")}</p>
        <Input placeholder={t("身份驗證時填寫的姓氏")} value={firstName} className="input" onChange={value => setFirstName(value)} />
      </div>
      <div className="session-div">
        <p className="name">{t("名字")}</p>
        <Input placeholder={t("身份驗證時填寫的名字")} value={secondName} className="input" onChange={value => setSecondName(value)} />
      </div>
      <Button 
        className="next-Button"
        disabled={!number || !firstName || !secondName}
        onClick={() => onActivation()}
      >{t("激活")}</Button>
    </div>
  )
}
export default withRouter(ManagerAddressPage)