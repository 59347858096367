/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, TextArea, Button, Modal, Toast, Mask } from "antd-mobile"
import { useSessionStore } from "@/store"
import { post } from "@/service"
import { IMAGE } from "@/assets"
import './styles.less'

const ApplyInfoPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sApplyConfig } = sState
  const [visible, setVisible] = useState(false)
  const [countryCodeData, setCountryCodeData] = useState(null)
  const [maskModal, setMaskModal] = useState(false)

  useEffect(() => {
    async function getCountryCodeData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/countryCode/list")
        Toast.clear()
        var result = dataLetterSort(resp)
        setCountryCodeData(result)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getCountryCodeData()
  }, [])
  
  const handleBlur = () => {
    // 完整日期验证
    const fullRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/
    if (!fullRegex.test(sApplyConfig?.date) && !!sApplyConfig?.date) {
      sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: true}})
    } else {
      sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: false}})
    }
}

  return (
    <div className="apply-info-page">
      <div className="navbar">
      {
        sessionStorage.getItem("startTag") === "open" ? (
          <img src={IMAGE.home} alt="" onClick={() => setVisible(true)} />
        ) : (
          <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        )
      }
      </div>
      <p className='title'>{t("您的个人信息")}</p>
      <div className="session-div">
        <p className="name">{t("姓氏")}</p>
        <Input placeholder={t("拼音或者英文")} value={sApplyConfig?.lastName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, lastName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("名字")}</p>
        <Input placeholder={t("拼音或者英文")} value={sApplyConfig?.firstName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, firstName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("出生日期")}</p>
        <Input placeholder="MM / DD / YYYY" value={sApplyConfig?.date} className={`input ${sApplyConfig?.errorDate ? "input-error" : ""}`} onBlur={handleBlur} onChange={value => {
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: true}})
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, date: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("手机号")}</p>
        <div className='phome-div'>
          <div className='code-div' onClick={() => setMaskModal(true)}>
            <p>{`+${sApplyConfig?.codeData?.numberCode || ""}`}</p>
            <img src={IMAGE.arrowBottom} alt="" />
          </div>
          <Input placeholder={t("手机号")} value={sApplyConfig?.mobile} className="input" style={{ "--mt": 0 }} onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, mobile: value}})} />
        </div>
      </div>
      <div className="session-div">
        <p className="name">{t("身份证号")}</p>
        <Input placeholder={t("身份证号")} value={sApplyConfig?.passportNumber} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, passportNumber: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("城市")}</p>
        <Input placeholder={t("中文")} value={sApplyConfig?.city} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, city: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("居住地址")}</p>
        <TextArea placeholder={t("中文")} autoSize value={sApplyConfig?.residentialAddress} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, residentialAddress: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("邮编")}</p>
        <Input placeholder={t("邮编")} value={sApplyConfig?.zipCode} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, zipCode: value}})} />
      </div>
      <Button 
        className="next-Button"
        disabled={!sApplyConfig?.lastName || !sApplyConfig?.firstName || !sApplyConfig?.date || sApplyConfig?.errorDate || !sApplyConfig?.mobile || !sApplyConfig?.passportNumber || !sApplyConfig?.city || !sApplyConfig?.residentialAddress || !sApplyConfig?.zipCode || !sApplyConfig?.codeData}
        onClick={() => history.push("/openUpload")}
      >{t("继续")}</Button>
      <Modal visible={visible} bodyClassName="m_modal_logout" content={
        <div className="modal-content-div">
          <p className="title">{t("登出账号？")}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setVisible(false)}>{t("取消")}</Button>
            <Button className="button confirm" onClick={() => {
              localStorage.setItem("token", "")
              history.push("/open")
            }}>{t("登出")}</Button>
          </div>
        </div>
      } />
      <Mask visible={maskModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="bg-container">
          <div className="head-container">
            <div className="empty-div"></div>
            <p className="title">{t("国家代码")}</p>
            <img src={IMAGE.close_black} alt="" className="close-button" onClick={() => setMaskModal(false)} />
          </div>
          {
            !!countryCodeData && <div className="body-container">
              <div className="body-scroll">
                <ul className="content-list">
                {
                  Object.keys(countryCodeData).map((item, index) => {
                    return <li className="section-container" key={index}>
                      <p className="section-key">{item === "#" ? t("热门") : item}</p>
                      <ul className="section-value-list">
                      {
                        countryCodeData[item].map((codeData, idx) => {
                          return <li key={idx} className="section-value-item" onClick={() => {
                            sDispatch({key: "sApplyConfig", value: {...sApplyConfig, codeData: codeData}})
                            setMaskModal(false)
                          }}>
                            <p className="value-name">{codeData.name}</p>
                            <p className="value-code">{`+${codeData.numberCode}`}</p>
                            {
                              sApplyConfig?.codeData?.name === codeData.name && <img src={IMAGE.checked} alt="" className="checked" />
                            }
                          </li>
                        })
                      }
                      </ul>
                    </li>
                  })
                }
                </ul>
              </div>
            </div>
          }
        </div>
      </Mask>
    </div>
  )
}
export default withRouter(ApplyInfoPage)

const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["name"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}