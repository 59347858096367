/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tabbar } from "@/component"
import { Button, Toast, Popup } from 'antd-mobile'
import { post } from "@/service"
import QRCode from "qrcode.react"
import html2canvas from "html2canvas"
import { DelNum } from "@/tool"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [shareUrl, setShareUrl] = useState('')
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/jinhou/invite/info")
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  async function drawCanvas () {
    Toast.show({
      icon: "loading",
      content: t("海报生成中…"),
      duration: 0
    })
    const dom = document.getElementById("printHtml")
    try {
      let canvas = await html2canvas(dom, {
        useCORS: true,
        allowTaint: true,
        height: dom.clientHeight - 5,
      })
      if (canvas) {
        let dataUrl = canvas.toDataURL("image/png")
        Toast.clear()
        setShareUrl(dataUrl)
        setVisible(true)
      }
    } catch (error) {
      Toast.show({
        icon: "fail",
        content: error,
      })
    }
  }

  function toCommission() {
    history.push({
      pathname: "/invitionCommission",
      state: {
        allReward: data.allReward,
        swapReward: data.swapReward,
        inviteOpenCardReward: data.inviteOpenCardReward,
      }
    })
  }

  function toPeople() {
    history.push({
      pathname: "/invitionPeople",
      state: {
        inviteOpenCardUserNum: data.inviteOpenCardUserNum,
        inviteUserNum: data.inviteUserNum
      }
    })
  }
  
  return (
    <div className="invition-page">
      <img src={IMAGE.invitionImage} alt="" className="image" />
      <p className="title">{t("邀请好友，赚取返佣")}</p>
      <p className="content">{t("开卡返佣50%")}</p>
      {
        !!data && <>
          <div className="data-list">
            <div className="data-item" onClick={() => toCommission()}>
              <p className="value">{`$${DelNum(data.allReward)}`}</p>
              <p className="key">{t("总返佣")}</p>
            </div>
            <div className="data-item" onClick={() => toPeople()}>
              <p className="value">{`${data.inviteOpenCardUserNum} / ${data.inviteUserNum}`}</p>
              <p className="key">{t("开卡人数 / 邀请人数")}</p>
            </div>
            {/* <div className="data-item" onClick={() => toCommission()}>
              <p className="value">{`$${DelNum(data.swapReward)}`}</p>
              <p className="key">{t("兑换返佣")}</p>
            </div>
            <div className="data-item" onClick={() => toCommission()}>
              <p className="value">{`$${DelNum(data.inviteOpenCardReward)}`}</p>
              <p className="key">{t("开卡返佣")}</p>
            </div> */}
          </div>
          <div className="copy-div" style={{marginTop: "28px"}}>
            <p className="key">{t("邀请码")}</p>
            <p className="value">{data.inviteCode}</p>
            <CopyToClipboard text={data.inviteCode} onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
              <img src={IMAGE.copy} alt="" />
            </CopyToClipboard>
          </div>
          <div className="copy-div" style={{marginTop: "8px"}}>
            <p className="key">{t("邀请链接")}</p>
            <p className="value">{data.inviteLink}</p>
            <CopyToClipboard text={data.inviteLink} onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
              <img src={IMAGE.copy} alt="" />
            </CopyToClipboard>
          </div>
          <Button className="share-button" onClick={() => drawCanvas()}>{t("分享海报")}</Button>
        </>
      }
      <div className="poster-div">
        <div id="printHtml" className="poster">
          <img crossOrigin="anonymous" src={IMAGE.share_image + "?time=" + new Date().valueOf()} alt="" className="bg-image" />
          <div className="url-div">
            <p className="url">{data?.inviteLink}</p>
          </div>
          <div className="qr-code-div">
            <QRCode 
              id="qrCode" 
              value={data?.inviteLink}
              size={85}
              fgColor="#000000"
            />
          </div>
        </div>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyClassName="share-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible(false)} />
        <img src={shareUrl} alt="" className="share-image" />
        <p className="save-text">{t("长按海报保存到相册")}</p>
      </Popup>
      <Tabbar selIndex={2} />
    </div>
  )
}
export default withRouter(IndexPage)