import React from 'react'
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import './styles.less'

const JoinPage = ({history}) => {

    return (
      <div className="browser-page">
				<img src={IMAGE.brown} alt="" className='image1' />
				<img src={IMAGE.brownLogo} alt="" className='image2' />
      </div>
    )
}
export default withRouter(JoinPage)