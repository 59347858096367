/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import QRCode from "qrcode.react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Toast, Modal, Button } from 'antd-mobile'
import { post } from "@/service"
import "./styles.less"

const supportWeChat = "renrenpaymoney"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [network, setNetwork] = useState(0)
  const [data, setData] = useState(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/info/account", {
          "token": "USDT"
        })
        Toast.clear()
        setData(resp.token)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="recharge-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_white} alt="" onClick={() => history.goBack()} />
        <p className="title">{t("USDT充值")}</p>
      </div>
      {
        !!data && <>
          <div className="body-div">
            <p className="network">{t("区块链")}</p>
            <div className="network-list">
            {
              data.chargeChainTypeObjects.map((item, index) => {
                return  <div key={index} className={`network-item ${network === index ? 'network-item-sel' : ''}`} onClick={() => setNetwork(index)}>{item.name}</div>
              })
            }
            </div>
            <div className="qr-code-div">
              <QRCode 
                id="qrCode" 
                value={data.chargeAddress[network].address}
                size={180}
                fgColor="#000000"
              />
            </div>
            <p className="address">{data.chargeAddress[network].address}</p>
            <div className="minimum">
              <p style={{ color: '#808080' }}>{t("最低充值金额")}</p>
              <p style={{ color: '#212121' }}>{`${data.chargeChainTypeObjects[network].minChargeAmount} USDT`}</p>
            </div>
            <CopyToClipboard text={data.chargeAddress[network].address} onCopy={() => Toast.show({
              icon: "success",
              content: t("复制成功"),
            })}>
              <div className="copy-button">{t("复制地址")}</div>
            </CopyToClipboard>
          </div>
          {/* <img src={IMAGE.rechargeLogo} alt="" className="recharge-logo" onClick={() => window.open("https://futurespay.net/")} /> */}
          {/* <div className="customer-button" onClick={() => setVisible(true)}>
            <img src={IMAGE.customer} alt="" />
            <p>{t("客服代充")}</p>
          </div> */}
          <Modal visible={visible} bodyClassName="recharge-modal" content={
            <div className="modal-content-div">
              <p className="title">{t("添加客服微信号")}</p>
              <p className="content">{`微信号：${supportWeChat}`}<br /><span>{t("备注请注明: Dengta代充")}</span></p>
              <div className="buttons-div">
                <Button className="button cancle" onClick={() => setVisible(false)}>{t("取消")}</Button>
                <CopyToClipboard text={supportWeChat} onCopy={() => {
                  setVisible(false)
                  Toast.show({
                    icon: "success",
                    content: t("复制成功"),
                  })
                }}>
                  <Button className="button confirm">{t("复制微信号")}</Button>
                </CopyToClipboard>
              </div>
            </div>
          } />
        </>
      }
    </div>
  )
}
export default withRouter(IndexPage)