import React, {useState, useEffect, useRef} from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast } from 'antd-mobile'
import { post, get } from '@/service'
import { IMAGE } from "@/assets"
import './styles.less' 

const LoginPage = ({ history }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState("")
  const [value2, setValue2] = useState("")
  const [onFocus, setOnFocus] = useState(false)
  const [onFocus2, setOnFocus2] = useState(false)
  const [first, setFirst] = useState(false)
  const [onFocusCode, setOnFocusCode] = useState(false)
  const [codeValue, setCodeValue] = useState("")
  const [timeOut, setTimeOut] = useState(0)
  const timerID = useRef()
  
  useEffect(() => {
    async function isFirstLogin() {
      const inputValue = value.trim()
      const resp = await get("app/account/email/firstLogin", {
        email: inputValue
      })
      setFirst(resp)
    }
    if (onFocus) return
    if (!value) return
    isFirstLogin()
  }, [onFocus, value])

  async function toCaptcha() {
    const inputValue = value.trim()
    const inputValue2 = value2.trim()
   if (!inputValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入你的账号"),
      })
    } else if (!inputValue2) {
      Toast.show({
        icon: 'fail',
        content: t("请输入密码"),
      })
    } else if (first && !codeValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入邮箱验证码"),
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("app/captcha/needCaptcha", {
          email: inputValue
        })
        Toast.clear()
        const {need} = resp
        const type = "TENCENT"
        if (need) {
          if (type === "TENCENT") {
            initTencentCaptcha(inputValue, inputValue2, type).show()
          } else {
            // 6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4
            window.grecaptcha.ready(function() {
              window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
                tologin({
                  email: inputValue,
                  password: inputValue2,
                  captchaType: type,
                  tencentRandstr: null,
                  tencentTicket: null,
                  googleResponse: token
                })
              })
            })
          }
        } else {
          tologin({
            email: inputValue,
            password: inputValue2,
            captchaType: type,
            tencentRandstr: null,
            tencentTicket: null,
            googleResponse: null
          })
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }

    function initTencentCaptcha(email, password, type) {
      return new window.TencentCaptcha('2038742797', function(res) {
        const {ticket, randstr} = res
        if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
          tologin({
            email: email,
            password: password,
            captchaType: type,
            tencentRandstr: randstr,
            tencentTicket: ticket,
            googleResponse: null
          }) 
        }
      })
    }

    async function tologin(data) {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const _data = first ? {...data, verifyCode: codeValue} : data
        const resp = await post("/app/account/login/email", _data)
        Toast.clear()
        localStorage.setItem("token", resp.token)
        history.push("/")
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
  }

  async function toCaptcha2() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const inputValue = value.trim()
      const resp = await post("app/captcha/needCaptcha", {
        email: inputValue
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha2(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              sendVerifyCode({
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha2(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        }) 
      }
    })
  }

  async function sendVerifyCode(result) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const inputValue = value.trim()
      result["email"] = inputValue
      result["tag"] = "LOGIN_VERIFY"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: t("成功")
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  return (
    <div className="login-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{t("登录")}</p>
      <p className="subtitle">{t("邮箱")}</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <p className="subtitle">{t("密码")}</p>
      <div className={`input-container ${onFocus2 ? 'input-container-active' : ''}`}>
        <Input value={value2} onFocus={() => setOnFocus2(true)} onBlur={() => setOnFocus2(false)} type='password' onChange={val => { setValue2(val) }} className="input-div" />
      </div>
      {
        !!first && (
          <p className="subtitle">{t("邮箱验证码")}</p>
        )
      }
      {
        !!first && (
          <div className={`input-container ${onFocusCode ? 'input-container-active' : ''}`}>
            <Input clearable value={codeValue} onFocus={() => setOnFocusCode(true)} onBlur={() => setOnFocusCode(false)} onChange={val => { setCodeValue(val) }} className="input-div" />
            {
              timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha2()}>{t("发送")}</p> : <p className="resend-button resend-button-disable">{`${t("重新发送")} ${timeOut}s`}</p>
            }
          </div>
        )
      }
      <Button className="next-button" onClick={() => toCaptcha()}>{t("登录")}</Button>
      <p className='forget-button' onClick={() => history.push("/forgetPassword")}>{t("忘记密码？")}</p>
    </div>
  )
}
export default withRouter(LoginPage)