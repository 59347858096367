import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast, Mask } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import { dataLetterSort } from '@/tool'
import './styles.less' 

const RegisterPage = ({ history }) => {
  const { t } = useTranslation()
  const [referralCode, setReferralCode] = useState("")
  const [type, setType] = useState("email")
  const [value, setValue] = useState("")
  const [value2, setValue2] = useState("")
  const [countryCodeInfo, setCountryCodeInfo] = useState()
  const [countryCodeData, setCountryCodeData] = useState(null)
  const [maskModal, setMaskModal] = useState(false)
  const [onFocus, setOnFocus] = useState(false)
  const [onFocus2, setOnFocus2] = useState(false)

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _state = history.location.state
      setReferralCode(_state.code)
      setValue2(_state.code)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let registerStorage = JSON.parse(sessionStorage.getItem("register-session"))
    if (!!registerStorage) {
      setCountryCodeInfo(registerStorage.countryCodeInfo)
      // setType(registerStorage.type)
      setValue(registerStorage.inputValue)
    } else {
      setCountryCodeInfo({id: '', alphaCode: 'US', numberCode: '1', name: 'United State'})
      // setType("phone")
      setValue("")
    }
  }, [])

  async function getCountryCodeData() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("/app/countryCode/list")
      Toast.clear()
      let datas = []
      resp.forEach(element => {
        if (element.numberCode !== "86") {
          datas.push(element)
        }
      })
      var result = dataLetterSort(datas)
      setCountryCodeData(result)
      setMaskModal(true)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }
  
  async function checkAccount() {
    const inputValue = value.trim()
    if (!inputValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入你的账号"),
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/checkAccount/email", {
          "email": inputValue
        })
        Toast.clear()
        if (!!resp.exitCashUser) {
          Toast.show({
            icon: 'fail',
            content: t("账号已存在"),
          })
        } else if (!!resp.exitPiFuturesUser) {
          Toast.show({
            icon: 'fail',
            content: t("已经有相同的账号")
          })
        } else {
          toCaptcha(inputValue)
          sessitionStorageEvent()
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error,
        })
      }
    }
  }

  async function toCaptcha(email) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: email
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type, email).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              history.push({
                pathname: "/openVerifyAccount", 
                state: { 
                  type: "email",
                  captchaType: type,
                  referralCode: value2,
                  account: email,
                  tencentRandstr: null,
                  tencentTicket: null,
                  googleResponse: token
                }
              })
            })
          })
        }
      } else {
        history.push({
          pathname: "/openVerifyAccount", 
          state: { 
            type: "email",
            captchaType: type,
            referralCode: value2,
            account: email,
            tencentRandstr: null,
            tencentTicket: null,
            googleResponse: null
          }
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha(type, email) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        history.push({
          pathname: "/openVerifyAccount", 
          state: { 
            type: "email",
            captchaType: type,
            referralCode: value2,
            account: email,
            tencentRandstr: randstr,
            tencentTicket: ticket,
            googleResponse: null
          }
        })
      }
    })
  }

  function sessitionStorageEvent() {
    const inputValue = value.trim()
    sessionStorage.setItem("register-session", JSON.stringify({
      countryCodeInfo: countryCodeInfo,
      inputValue: inputValue,
      type: type
    }))
  }

  return (
    <div className="p_openRegister">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{t("注册")}</p>
      <div className="select-container">
        {/* <p className={`select-menu ${type === "phone" ? "active" : ""}`} onClick={() => setType("phone")}>手机</p> */}
        <p className={`select-menu ${type === "email" ? "active" : ""}`} onClick={() => setType("email")}>{t("邮箱")}</p>
      </div>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        {
          type === "phone" && <p className="country-code" onClick={() => {
            if (!!countryCodeData) {
              setMaskModal(true)
            } else {
              getCountryCodeData()
            }
          }}>{`+${countryCodeInfo.numberCode}`}</p>
        }
        <Input value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      {
        (!referralCode || referralCode === "") && <>
        <p className="referral-id">{t("邀请码")}</p>
        {/* <div className="referral-id-container">{referralCode}</div> */}
        <div className={`input-container ${onFocus2 ? 'input-container-active' : ''}`}>
          <Input value={value2} placeholder={t("选填")} onFocus={() => setOnFocus2(true)} onBlur={() => setOnFocus2(false)} onChange={val => { setValue2(val) }} className="input-div" />
        </div>
      </>
      }
      <Button className="next-button" onClick={() => checkAccount()}>{t("继续")}</Button>
      <Mask visible={maskModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="bg-container">
          <div className="head-container">
            <div className="empty-div"></div>
            <p className="title">{t("国家代码")}</p>
            <img src={IMAGE.close_black} alt="" className="close-button" onClick={() => setMaskModal(false)} />
          </div>
          {
            !!countryCodeData && <div className="body-container">
              <div className="body-scroll">
                <ul className="content-list">
                {
                  Object.keys(countryCodeData).map((item, index) => {
                    return <li className="section-container" key={index}>
                      <p className="section-key">{item === "#" ? "Popular" : item}</p>
                      <ul className="section-value-list">
                      {
                        countryCodeData[item].map((codeData, idx) => {
                          return <li key={idx} className="section-value-item" onClick={() => {
                            setCountryCodeInfo(codeData)
                            setMaskModal(false)
                          }}>
                            <p className="value-name">{codeData.name}</p>
                            <p className="value-code">{`+${codeData.numberCode}`}</p>
                            {
                              countryCodeInfo.name === codeData.name && <img src={IMAGE.checked} alt="" className="checked" />
                            }
                          </li>
                        })
                      }
                      </ul>
                    </li>
                  })
                }
                </ul>
              </div>
            </div>
          }
        </div>
      </Mask>
    </div>
  )
}
export default withRouter(RegisterPage)