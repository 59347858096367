import { UStorage } from "@/util"

const reducer = (state, action) => {
  UStorage.sets(action.key, action.value)
  switch(action.key) {
    case "sApplyConfig":
      return { ...state, sApplyConfig: action.value }
    case "sOrderConfig":
      return { ...state, sOrderConfig: action.value }
    default:
      return state
  }
}

export default reducer