import React, { useState } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast, Modal } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import './styles.less'

const ManagerPinPage = ({history}) => {
  const { t } = useTranslation()
  const [pin, setPin] = useState("")
  const [visible, setVisible] = useState(false)

  const onConfirm = async () => {
    try {
      Toast.show({
        icon: "loading",
        content: t("加载中..."),
        duration: 0
      })
      await post("app/account/payment_code", {
        code: pin
      })
      Toast.clear()
      setTimeout(() => {
        if (sessionStorage.getItem("startTag") === "open") {
          history.push("/openPin")
        } else {
          history.replace("/openPin")
        }
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  return (
    <div className="manager-pin-page">
      <div className="navbar">
        {
          sessionStorage.getItem("startTag") === "open" ? (
            <img src={IMAGE.home} alt="" onClick={() => setVisible(true)} />
          ) : (
            <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
          )
        }
      </div>
      <p className='title'>{ t("申请开卡") }</p>
      <p className='subtitle'>{t("输入付款码用于支付开卡费")}</p>
      <div className="session-div">
        <p className="name">{t("8位付款码")}</p>
        <Input placeholder="PIN" value={pin} type='text' className={`input ${pin.length > 0 && pin.length !== 8 ? "input-error" : ""}`} onChange={value => setPin(value)} />
      </div>
      <div className='flex1' />
      <Button 
        className="next-Button"
        disabled={!pin || pin.length !== 8}
        onClick={() => onConfirm()}
      >{t("继续")}</Button>
      <Modal visible={visible} bodyClassName="m_modal_logout" content={
        <div className="modal-content-div">
          <p className="title">{t("登出账号？")}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setVisible(false)}>{t("取消")}</Button>
            <Button className="button confirm" onClick={() => {
              localStorage.setItem("token", "")
              history.push("/open")
            }}>{t("登出")}</Button>
          </div>
        </div>
      } />
    </div>
  )
}
export default withRouter(ManagerPinPage)