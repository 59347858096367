/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Switch, Toast } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import './styles.less'

const ManagerPage = ({history}) => {
  const { t } = useTranslation()
  const { sDispatch } = useSessionStore()
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/info")
        const resp2 = await post("/card/applyOrder")
        Toast.clear()
        setData(resp)
        if (resp2.length > 0) {
          const _data = resp2[resp2.length - 1]
          sDispatch({key: "sOrderConfig", value: {
            address: "",
            mobile: "",
            name: "",
            hasOrder: true
          }})
        }
      } catch (error) {
        console.log("error:", error)
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  return (
    <div className="manager-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("管理您的卡")}</p>
      <div className='session-item' onClick={() => history.push("/cardInfo")}>
        <p>{t("查看卡信息")}</p>
        <img src={IMAGE.arrowRight} alt="" />
      </div>
      <div className='session-item'  onClick={() => {
        if (!!data?.hasPaymentPassword) {
          history.push("/managerAddress")
        } else {
          history.push("/managerPin")
        }
      }}>
        <p>{t("订购实体卡")}</p>
        <img src={IMAGE.arrowRight} alt="" />
      </div>
      <div className='session-item' onClick={() => history.push("/managerPin")}>
        <p>{t("设置PIN")}</p>
        <img src={IMAGE.arrowRight} alt="" />
      </div>
      {/* <div className='session-item'>
        <p>锁定卡</p>
        <Switch />
      </div> */}
    </div>
  )
}
export default withRouter(ManagerPage)