import React from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Input, Button } from "antd-mobile"
import { IMAGE } from "@/assets"
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sOrderConfig } = sState

  return (
    <div className="manager-address-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("卡信息")}</p>
      <div className="session-div">
        <p className="name">{t("卡上的名字")}</p>
        <Input placeholder={t("英文/拼音")} value={sOrderConfig?.firstName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sOrderConfig", value: {...sOrderConfig, firstName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("卡上的姓氏")}</p>
        <Input placeholder={t("英文/拼音")} value={sOrderConfig?.lastName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sOrderConfig", value: {...sOrderConfig, lastName: value}})
        }} />
      </div>
      <div className='flex1' />
      <Button 
        className="next-Button"
        disabled={!sOrderConfig?.firstName || !sOrderConfig?.lastName}
        onClick={() => history.push("/managerAddress")}
      >{t("确定")}</Button>
    </div>
  )
}
export default withRouter(ManagerAddressPage)