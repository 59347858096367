/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { Toast } from 'antd-mobile'
import { post } from "@/service"
import moment from "moment"
import { DelNum } from "@/tool"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const {allReward, swapReward, inviteOpenCardReward} = history.location.state
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/jinhou/invite/reward_list", {
          page: 1,
          pageSize: 100
        })
        Toast.clear()
        setData(resp.content)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="commission-page">
       <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p className="title">{t("返佣记录")}</p>
      </div>
      <div className="data-list">
        <div className="data-item">
          <p className="value">{`$${DelNum(allReward)}`}</p>
          <p className="key">{t("总返佣")}</p>
        </div>
        <div className="data-item">
          <p className="value">{`$${DelNum(swapReward)}`}</p>
          <p className="key">{t("兑换返佣")}</p>
        </div>
        <div className="data-item">
          <p className="value">{`$${DelNum(inviteOpenCardReward)}`}</p>
          <p className="key">{t("开卡返佣")}</p>
        </div>
      </div>
      {
        !!data && <div className="record-list">
        {
          data.map((item, index) => {
            return <div key={index} className="record-item">
              <img src={item.type === "EXCHANGE" ? IMAGE.commission_icon_1 : IMAGE.commission_icon_2} alt="" />
              <div className="item-content">
                <div className="except-time-div">
                  <p className="email">{item.email}</p>
                  <p className="value">{`+$${DelNum(item.amount)}`}</p>
                </div>
                <p className="time">{ moment(item.time).format("YYYY-MM-DD HH:mm") }</p>
              </div>
            </div>
          })
        }
        </div>
      }
    </div>
  )
}
export default withRouter(IndexPage)