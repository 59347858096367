import card_icon_1 from "./assets/card-icon-1.png"
import card_icon_2 from "./assets/card-icon-2.png"
import card_icon_3 from "./assets/card-icon-3.png"
import card_icon_4 from "./assets/card-icon-4.png"
import card_icon_5 from "./assets/card-icon-5.png"
import card_icon_6 from "./assets/card-icon-6.png"
import addFunds from "./assets/add-funds.png"
import cardImage from "./assets/card-image.png"
import back_black from "./assets/back-black.png"
import back_white from "./assets/back-white.png"
import copy from "./assets/copy.png"
import close_black from "./assets/close-black.png"
import usd from "./assets/usd.png"
import usdt from "./assets/usdt.png"
import assets from "./assets/assets.png"
import assetsSel from "./assets/assets-sel.png"
import card from "./assets/card.png"
import cardSel from "./assets/card-sel.png"
import invition from "./assets/invition.png"
import invitionSel from "./assets/invition-sel.png"
import profile from "./assets/profile.png"
import profileSel from "./assets/profile-sel.png"
import assetsImage from "./assets/assets-image.png"
import deposit from "./assets/deposit.png"
import withdraw from "./assets/withdraw.png"
import pay from "./assets/pay.png"
import swap from "./assets/swap.png"
import swapIcon from "./assets/swap-icon.png"
import invitionImage from "./assets/invition-image.png"
import commission_icon_1 from "./assets/commission-icon-1.png"
import commission_icon_2 from "./assets/commission-icon-2.png"
import clickTag from "./assets/click-tag.png"
import share_image from "./assets/share-bg-image-2.png"
import join_image from "./assets/join-image-2.png"
import checked from "./assets/checked.png"
import brown from "./assets/brown.png"
import brownLogo from "./assets/brown-logo.png"
import rechargeLogo from "./assets/recharge-logo.png"
import customer from "./assets/customer.png"
import customer2 from "./assets/customer2.png"
import select_icon from "./assets/select-icon.png"
import customerCode from "./assets/customer-code2.jpeg"
import arrowBottom from "./assets/arrow-bottom.png"
import passport from "./assets/passport.png"
import idCard from "./assets/idCard.png"
import arrowRight from "./assets/arrow-right.png"
import name from "./assets/name.png"
import mobile from "./assets/mobile.png"
import address from "./assets/address.png"
import order from "./assets/order.png"
import transfer from "./assets/transfer.png"
import arrowTop from "./assets/arrow_top.png"
import line1 from "./assets/line1.png"
import line2 from "./assets/line2.png"
import quotaTop from "./assets/quota_top.png"
import quotaBottom from "./assets/quota-bottom.png"
import quotaDesc from "./assets/quota-desc.png"
import flowerLeft from "./assets/flower_left.png"
import flowerRight from "./assets/flower_right.png"
import quatoReduce from "./assets/quota_reduce.png"
import quatoAdd from "./assets/quato_add.png"
import home from "./assets/home.png"
import successLogo from "./assets/successLogo.png"
import result1 from "./assets/result1.png"
import result2 from "./assets/result2.png"
import result3 from "./assets/result3.png"
import pay1 from "./assets/pay1.png"
import pay2 from "./assets/pay2.png"
import arrow1 from "./assets/arrow1.png"
import arrow2 from "./assets/arrow2.png"
import kyc1 from "./assets/kyc1.jpg"
import kyc2 from "./assets/kyc2.jpg"
import kyc3 from "./assets/kyc3.png"

const IMAGE = {
  card_icon_1, card_icon_2, card_icon_3, card_icon_4, card_icon_5, card_icon_6, addFunds, cardImage, back_black, back_white, copy, close_black, usd, usdt, assets, assetsSel, card, cardSel, invition, invitionSel, profile, profileSel, assetsImage, deposit, withdraw, pay, swap, swapIcon, invitionImage, commission_icon_1, commission_icon_2, clickTag, share_image, join_image, checked, brown, brownLogo, rechargeLogo, customer, select_icon, customer2, customerCode, arrowBottom, passport, idCard, arrowRight, name, mobile, address, order, transfer, arrowTop, line1, line2, quotaTop, quotaBottom, quotaDesc, flowerLeft, flowerRight, quatoReduce, quatoAdd, home, successLogo, result1, result2, result3, pay1, pay2, arrow1, arrow2, kyc1, kyc2, kyc3
}

export default IMAGE