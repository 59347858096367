import axios from "axios"

const BASEURL = "https://api.dengta.app"

const instance = axios.create({
  timeout: 30000,
  baseURL: BASEURL
})
// 请求拦截
instance.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("token")
    }
    return config
  }, 
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let response = res.data
      if (response.code === 0) {
        return response.data
      } else if (response.code === 90004 && !window.location.pathname.startsWith('/join')) {
        window.location.href = "/join"
      } else {
        const messge = getErrorMessage(response.code, response.message)
        return Promise.reject(messge)
      }
    } else {
      return Promise.reject(res.statusText)
    }
  }, 
  error => {
    if (error.message.includes('timeout')) {
      return Promise.reject('網路異常，請求逾時')
    } else if (error.message.includes('Network Error')) {
      return Promise.reject('網路錯誤')
    } else {
      return Promise.reject(error.message)
    }
  }
)
// 请求方法
export const post = function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url,params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const get = function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const upload = function upload(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(BASEURL + url, params, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem("token")
      }
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const getErrorMessage = (code, message) => {
  if (code === 500) {
    return "內部伺服器錯誤。"
  } else if (code === 1001) {
    return "請求參數無效。"
  } else if (code === 1002) {
    return "缺少參數。"
  } else if (code === 10001) {
    return "用戶不存在。"
  } else if (code === 10002) {
    return "密碼不匹配。"
  } else if (code === 10003) {
    return "無效的請求。"
  } else if (code === 10004) {
    return "系統正在維護中。請稍後再試。"
  } else if (code === 10005) {
    return "帳號已被封鎖。"
  } else if (code === 10006) {
    return "請求過於頻繁。"
  } else if (code === 10007) {
    return "系統繁忙。"
  } else if (code === 10008) {
    return "單一裝置帳號過多。"
  } else if (code === 10011) {
    return "驗證碼無效。"
  } else if (code === 10012) {
    return "驗證碼已過期。"
  } else if (code === 10013) {
    return "發送驗證碼失敗。"
  } else if (code === 10017) {
    return "身份驗證碼請求不存在。"
  } else if (code === 10018) {
    return "此號碼與任何用戶無關。"
  } else if (code === 10019) {
    return "重複的密碼。"
  } else if (code === 10022) {
    return "Google驗證碼不匹配。"
  } else if (code === 10024) {
    return "新的Google驗證碼不匹配。"
  } else if (code === 10025) {
    return "推薦ID已設定。"
  } else if (code === 10026) {
    return "推薦ID不存在。"
  } else if (code === 10028) {
    return "缺少Google驗證碼。"
  } else if (code === 20001) {
    return "帳號不存在。"
  } else if (code === 20002) {
    return "帳戶餘額不足。"
  } else if (code === 20003) {
    return "用戶名或密碼錯誤。"
  } else if (code === 20004) {
    return "不能轉移到不同的資產。"
  } else if (code === 20006) {
    return "帳號已存在。"
  } else if (code === 20009) {
    return "邀請碼不存在。"
  } else if (code === 20010) {
    return "過於頻繁發送驗證碼。"
  } else if (code === 20011) {
    return "找不到被邀請的用戶。"
  } else if (code === 20012) {
    return "邀請獎勵已經發放。"
  } else if (code === 20013) {
    return "過於頻繁發送驗證請求。"
  } else if (code === 20015) {
    return "無效的地址格式。"
  } else if (code === 20016) {
    return "邀請碼無效。"
  } else if (code === 20018) {
    return "已註冊，請登入。"
  } else if (code === 20021) {
    return "數字為負數。"
  } else if (code === 20022) {
    return "電子郵件已存在。"
  } else if (code === 20025) {
    return "請選擇網絡。"
  } else if (code === 20026) {
    return "金額超過剩餘提款限額。"
  } else if (code === 40001) {
    return "錢包不存在。"
  } else if (code === 40008) {
    return "餘額不足。"
  } else if (code === 90001) {
    return "JWT令牌已過期。"
  } else if (code === 90002) {
    return "JWT令牌無效。"
  } else if (code === 90003) {
    return "JWT簽名驗證失敗。"
  } else if (code === 90004) {
    return "用戶未登入。"
  } else if (code === 90005) {
    return "用戶不存在。"
  } else if (code === 100001) {
    return "帳戶不存在。"
  } else if (code === 100004) {
    return "請勿向自己支付。"
  } else if (code === 100007) {
    return "電子郵件格式不正確。"
  } else if (code === 100017) {
    return "未激活。"
  } else if (code === 100019) {
    return "請輸入電子郵件驗證碼。"
  } else if (code === 100020) {
    return "請輸入Google驗證碼。"
  } else if (code === 100023) {
    return "網絡不穩定。"
  } else if (code === 100026) {
    return "該帳戶已被永久封禁，您已失去對帳戶的所有權限。"
  } else if (code === 100027) {
    return "安全信息變更後24小時內禁止提款。"
  } else if (code === 100029) {
    return "服務正在維護中。"
  } else if (code === 100030) {
    return "資產無效。"
  } else if (code === 100036) {
    return "無法兌換。"
  } else if (code === 100037) {
    return "很抱歉，該服務暫時不可用。"
  } else if (code === 100040) {
    return "僅限系統用戶。"
  } else if (code === 100042) {
    return "該貨幣不支持提款。"
  } else if (code === 100043) {
    return "該貨幣不支持轉帳。"
  } else if (code === 100044) {
    return "權限被拒絕。"
  } else if (code === 100045) {
    return "不支持此代幣。"
  } else if (code === 100048) {
    return "修改電子郵件的間隔必須超過1小時。"
  } else if (code === 100049) {
    return "此操作要求修改電子郵件後超過2天的間隔。"
  } else if (code === 100050) {
    return "密碼輸入錯誤次數過多。"
  } else if (code === 100051) {
    return "修改密碼的間隔必須超過2天。"
  } else if (code === 100052) {
    return "Google驗證碼輸入錯誤次數過多。請稍後再試。"
  } else if (code === 100053) {
    return "驗證碼輸入錯誤次數過多。請稍後再試。"
  } else if (code === 100054) {
    return "修改2FA的間隔必須超過2天。"
  } else if (code === 100055) {
    return "修改手機號碼的間隔必須超過2天。"
  } else if (code === 100056) {
    return "修改2FA的間隔必須超過1小時。"
  } else if (code === 100057) {
    return "修改付款PIN的間隔必須超過1天。"
  } else if (code === 100058) {
    return "付款密碼不匹配。正在登出！"
  } else if (code === 110001) {
    return "網絡繁忙。"
  } else if (code === 110002) {
    return "密碼錯誤。"
  } else if (code === 110003) {
    return "用戶名無效（a-z, 0-9, -, _, 4至20個字符）。"
  } else if (code === 110004) {
    return "請設置一個含有8-18個字母和數字的密碼。"
  } else if (code === 110005) {
    return "付款密碼不匹配。"
  } else if (code === 110006) {
    return "付款PIN未設置。"
  } else if (code === 110007) {
    return "付款密碼不匹配。如果下次仍不匹配，將登出賬號。"
  } else if (code === 200005) {
    return "帶寬太小。"
  } else if (code === 200006) {
    return "帶寬太大。"
  } else if (code === 210001) {
    return "今天已連接。"
  } else if (code === 210002) {
    return "已超過1000天。不再允許連接。"
  } else if (code === 210003) {
    return "已領取1000流量赠券。"
  } else {
    return message
  }
}
