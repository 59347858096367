import React from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { selIndex } = props
  const history = useHistory()

  const tbs = [{
    image: 'card',
    name: t("卡"),
    go: '/'
  },{
    image: 'assets',
    name: t("资产"),
    go: '/token/USDT'
  // },{
  //   image: 'invition',
  //   name: t("邀请"),
  //   go: '/invition'
  },{
    image: 'profile',
    name: t("我的"),
    go: '/mine'
  }]

  return (
    <div className="tabbar-component">
    {
      tbs.map((item, index) => {
        return <div className="tabbar-div" key={index} onClick={() => history.push(item.go)}>
          <img src={IMAGE[index === selIndex ? `${item.image}Sel` : item.image]} alt="" />
          <p style={{ color: index === selIndex ? '#D1840A' : '#262626' }}>{ item.name }</p>
        </div>
      })
    }
    </div>
  )
}

export default IndexComponent