import { EventEmitter } from "events"

const NoticeEmitter = new EventEmitter()

export { NoticeEmitter }

export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return decodeURIComponent(pair[1]);}
	}
	return(false);
}

export function handlingErrorMessages(e) {
  let message = 
    e.data && e.data.message ? e.data.message : 
    e.error && JSON.parse(JSON.stringify(e.error)).body ? JSON.parse(JSON.parse(JSON.stringify(e.error)).body).error.message : 
    e.data ? e.data : 
    JSON.stringify(e)
  let error = JSON.parse(message)
  if (!!error && !!error.reason) {
    return error.reason
  } else {
    return null
  }
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["name"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}

export function inWechat() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 || typeof navigator.wxuserAgent !== 'undefined'
}

export function DelNum(number) {
  const _number = parseFloat(number)
  const __number = Math.floor(_number * 100)
  const hasZero1 = __number % 10 === 0 ? true : false
  const hasZero2 = __number % 100 === 0 ? true : false
  const ___number = Math.floor(__number) / 100
  const ____number = hasZero1 && hasZero2 ? Number(___number).toLocaleString() + '.00' : hasZero1 && !hasZero2 ? Number(___number).toLocaleString() + '0' : Number(___number).toLocaleString()
  return ____number
}