import React, { createContext, useReducer, useContext } from "react"
import reducer from "./reducer"

export const initState = {
  address: ""
}

const ConfigCtx = createContext(null)

export const Provider = props => {
  const [state, dispatch] = useReducer(reducer, initState)
  return <ConfigCtx.Provider value={{ state, dispatch }}>{ props.children }</ConfigCtx.Provider>
}

export const useConfigStore = () => useContext(ConfigCtx)