import React, { useState } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Toast } from "antd-mobile"
import { IMAGE } from "@/assets"
import { upload, post } from "@/service"
import './styles.less'

const ApplyInfoPage = ({history}) => {
  const { t } = useTranslation()
  const { sState } = useSessionStore()
  const { sApplyConfig } = sState
  const [idType, setIdType] = useState("")
  const [url1, setUrl1] = useState("")
  const [url2, setUrl2] = useState("")

  const onUpdate = async (e) => {
    Toast.show({
      icon: "loading",
      content: t("加载中..."),
      duration: 0
    })
    try {
      const files = e.target.files
      if (!files || files.length === 0) {
        return
      }
      const file = files[0]
      const compressedFile = await compressImage(file);
      let param = new FormData()
      param.append("file", compressedFile)
      const resp = await upload("/app/account/upload_passport_photo", param)
      if (resp.status !== 200) {
        Toast.show({
          icon: "fail",
          content: JSON.stringify(resp.statusText)
        })
        return
      } 
      const data = resp.data
      if (data.code !== 0) {
        Toast.show({
          icon: "fail",
          content: JSON.stringify(data.message)
        })
        return
      }
      if (!url1) {
        setUrl1(data.data)
      } else {
        setUrl2(data.data)
        onsubmit([url1, data.data])
      }
      // onsubmit(data.data)
      Toast.clear()
    } catch (error) {
      Toast.clear()
      Toast.show({
        icon: "fail",
        content: error
      })
    }
  }

  const onsubmit = async (urls) => {
    try {
      await post("/app/account/apply_lv3", {
        countryCode: sApplyConfig?.codeData?.alphaCode,
        regionCode: sApplyConfig?.codeData?.alphaCode,
        regionPhoneCode: sApplyConfig?.codeData?.numberCode,
        firstName: sApplyConfig?.firstName,
        lastName: sApplyConfig?.lastName,
        birthDate: sApplyConfig?.date,
        mobile: sApplyConfig?.mobile,
        idType: idType,
        passportNumber: sApplyConfig?.passportNumber,
        passportPhoto: urls,
        residentialAddress: sApplyConfig?.residentialAddress,
        city: sApplyConfig?.city,
        zipCode: sApplyConfig?.zipCode,
        state: "",
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t("提交成功"),
      })
      setTimeout(() => {
        history.push("/openSuccess")
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  return (
    <div className="apply-update-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("选择身份验证证件类型")}</p>
      {
        !((!!url1 && idType !== "PASSPORT") || !!url2) && (
          <div className='session-item' onClick={() => setIdType("PASSPORT")}>
            <img src={IMAGE.passport} alt="" draggable="false" />
            <p>{t("护照")}</p>
            <img src={IMAGE.arrowRight} alt="" />
            <input type="file" accept="image/*"  className="upload" onChange={onUpdate} />
          </div>
        )
      }
      {
        !((!!url1 && idType !== "IDCARD") || !!url2) && (
          <div className='session-item' onClick={() => setIdType("IDCARD")}>
            <img src={IMAGE.idCard} alt="" draggable="false" />
            <p>{t("身份证")}</p>
            <img src={IMAGE.arrowRight} alt="" />
            <input type="file" accept="image/*" className="upload" onChange={onUpdate} />
          </div>
        )
      }
      <div className='session-image'>
        {
          !!url1 && <img src={url1} alt="" />
        }
        {
          !!url2 && <img src={url2} alt="" />
        }
        {
          !!url1 && !url2 && <p>{t("再上传一张")}</p>
        }
      </div>
      <p className='txt'>{t("注意事項")}</p>
      <div className='example'>
        <img src={IMAGE.kyc1} alt="" />
        <img src={IMAGE.kyc2} alt="" />
        {/* <img src={IMAGE.kyc3} alt="" /> */}
      </div>
    </div>
  )
}
export default withRouter(ApplyInfoPage)

// function compressImage(file) {
//   return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.src = URL.createObjectURL(file);
//       img.onload = () => {
//           const canvas = document.createElement('canvas');
//           const ctx = canvas.getContext('2d');
//           const maxWidth = 800;  // 设置最大宽度
//           const maxHeight = 600; // 设置最大高度
//           let width = img.width;
//           let height = img.height;

//           // 调整画布大小和图片大小保持比例
//           if (width > height) {
//               if (width > maxWidth) {
//                   height *= maxWidth / width;
//                   width = maxWidth;
//               }
//           } else {
//               if (height > maxHeight) {
//                   width *= maxHeight / height;
//                   height = maxHeight;
//               }
//           }
//           canvas.width = width;
//           canvas.height = height;
//           ctx.drawImage(img, 0, 0, width, height);
          
//           // 转换canvas为blob并解析Promise
//           canvas.toBlob((blob) => {
//               if (blob) {
//                   resolve(new File([blob], file.name, {
//                       type: 'image/jpeg',
//                       lastModified: Date.now()
//                   }));
//               } else {
//                   reject(new Error('Canvas to Blob failed'));
//               }
//           }, 'image/jpeg', 0.1); // 质量设置为75%
//       };
//       img.onerror = error => reject(error);
//   });
// }

function compressImage(file, maxSizeMB = 20) {
  return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const maxWidth = 800  // 设置最大宽度
          const maxHeight = 600 // 设置最大高度
          let width = img.width
          let height = img.height

          // 调整画布大小和图片大小保持比例
          if (width > height) {
              if (width > maxWidth) {
                  height *= maxWidth / width
                  width = maxWidth
              }
          } else {
              if (height > maxHeight) {
                  width *= maxHeight / height
                  height = maxHeight
              }
          }
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)

          // 动态调整压缩质量以满足文件大小限制
          let quality = 1 // 初始质量设置为100%
          const maxSizeBytes = maxSizeMB * 1024 * 1024 // 最大大小转换为字节

          function adjustQualityAndResolve() {
              canvas.toBlob((blob) => {
                  if (blob.size <= maxSizeBytes) {
                      resolve(new File([blob], file.name, {
                          type: 'image/jpeg',
                          lastModified: Date.now()
                      }));
                  } else if (quality > 0.1) {
                      quality -= 0.1; // 每次质量减少10%
                      adjustQualityAndResolve(); // 递归调用直到满足大小要求
                  } else {
                      reject(new Error('Cannot compress image to desired size'));
                  }
              }, 'image/jpeg', quality);
          }

          adjustQualityAndResolve(); // 开始调整质量的递归调用
      };
      img.onerror = error => reject(error);
  });
}
