/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Mask, Toast, Button } from "antd-mobile"
import { useSessionStore } from "@/store"
import { get } from "@/service"
import { IMAGE } from "@/assets"
import './styles.less'

const ApplyPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sApplyConfig } = sState
  const [countryCodeData, setCountryCodeData] = useState(null)
  const [maskModal, setMaskModal] = useState(false)

  useEffect(() => {
    async function getCountryCodeData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await get("/app/account/mobile_region_code")
        Toast.clear()
        var result = dataLetterSort(resp)
        setCountryCodeData(result)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getCountryCodeData()
  }, [])

  return (
    <div className="apply-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <div className="process">
          <div className="line active" />
          <div className="line" />
          <div className="line" />
        </div>
      </div>
      <p className='title'>{t("您的居住地是？")}</p>
      <p className='session-title'>{t("国家或地区")}</p>
      <div className='session-container' onClick={() => setMaskModal(true)}>
        <p>{sApplyConfig?.codeData?.desc || ""}</p>
        <img src={IMAGE.arrowBottom} alt="" draggable="false" />
      </div>
      <div className='flex1' />
      <Button 
        className="next-Button"
        disabled={!sApplyConfig}
        onClick={() => history.push("/applyInfo")}
      >{t("继续")}</Button>
      <Mask visible={maskModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="bg-container">
          <div className="head-container">
            <div className="empty-div"></div>
            <p className="title">{t("国家代码")}</p>
            <img src={IMAGE.close_black} alt="" className="close-button" onClick={() => setMaskModal(false)} />
          </div>
          {
            !!countryCodeData && <div className="body-container">
              <div className="body-scroll">
                <ul className="content-list">
                {
                  Object.keys(countryCodeData).map((item, index) => {
                    return <li className="section-container" key={index}>
                      <p className="section-key">{item === "#" ? t("热门") : item}</p>
                      <ul className="section-value-list">
                      {
                        countryCodeData[item].map((codeData, idx) => {
                          return <li key={idx} className="section-value-item" onClick={() => {
                            // setCountryCodeInfo(codeData)
                            sDispatch({key: "sApplyConfig", value: {...sApplyConfig, codeData: codeData}})
                            setMaskModal(false)
                          }}>
                            <p className="value-name">{codeData.desc}</p>
                            <p className="value-code">{`+${codeData.phoneCode}`}</p>
                            {
                              sApplyConfig?.codeData?.desc === codeData.desc && <img src={IMAGE.checked} alt="" className="checked" />
                            }
                          </li>
                        })
                      }
                      </ul>
                    </li>
                  })
                }
                </ul>
              </div>
            </div>
          }
        </div>
      </Mask>
    </div>
  )
}
export default withRouter(ApplyPage)

const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["desc"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}