import React from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button } from 'antd-mobile'
import { IMAGE } from "@/assets"
import './styles.less'

const JoinPage = ({history}) => {
	const { t } = useTranslation()

    return (
      <div className="p_success">
				<img src={IMAGE.successLogo} alt="" />
				<p className='title'>{t("开户成功")}</p>
				<p className='content'>{t("我們正在審核您的資料，一旦通過將立即爲您寄出實體卡，收到卡片后即可激活使用")}</p>
				<Button className='button' onClick={() => history.push("/")}>{ t("好的") }</Button>
      </div>
    )
}
export default withRouter(JoinPage)