/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }  from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { Toast } from 'antd-mobile'
import { post } from "@/service"
import moment from "moment"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/message/systemList", {
          page: 1,
          pageSize: 100
        })
        Toast.clear()
        setData(resp.content)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="announcement-page">
       <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p className="title">{t("公告列表")}</p>
      </div>
      {
        !!data && <div className="list">
        {
          data.map((item, index) => {
            return <div key={index} className="item" onClick={() => {
              if (item.jumpType === "H5" && !!item.linkContent && item.linkContent !== "") {
                window.open(item.linkContent)
              } else {
                history.push({
                  pathname: "/announcementDetail",
                  state: {
                    data: item
                  }
                })
              }
            }}>
              <p className="title">{ item.title }</p>
              <p className="time">{ moment(item.time).format("YYYY-MM-DD HH:mm") }</p>
            </div>
          })
        }
        </div>
      }
    </div>
  )
}
export default withRouter(IndexPage)