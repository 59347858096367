/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Input, Button, Mask, Toast, TextArea } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sOrderConfig } = sState
  const [countryCodeData, setCountryCodeData] = useState(null)
  const [maskModal, setMaskModal] = useState(false)

  useEffect(() => {
    async function getCountryCodeData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/countryCode/list")
        Toast.clear()
        var result = dataLetterSort(resp)
        setCountryCodeData(result)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getCountryCodeData()
  }, [])


  return (
    <div className="manager-address-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("收货地址")}</p>
      <div className="session-div">
        <p className="name">{t("收件人")}</p>
        <Input placeholder={t("收件人名字")} value={sOrderConfig?.name} className="input" onChange={value => sDispatch({key: "sOrderConfig", value: {...sOrderConfig, name: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("手机号")}</p>
        <div className='input-container'>
          <div className='code-div' onClick={() => setMaskModal(true)}>
            <p>{`+${sOrderConfig?.codeData?.numberCode || ""}`}</p>
            <img src={IMAGE.arrowBottom} alt="" />
          </div>
          <Input placeholder={t("手机号")} value={sOrderConfig?.mobile} className="input2" onChange={value => sDispatch({key: "sOrderConfig", value: {...sOrderConfig, mobile: value}})} />
        </div>
      </div>
      <div className="session-div">
        <p className="name">{t("城市")}</p>
        <Input placeholder={t("城市")} value={sOrderConfig?.city} className="input" onChange={value => sDispatch({key: "sOrderConfig", value: {...sOrderConfig, city: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("邮编")}</p>
        <Input placeholder={t("邮编")} value={sOrderConfig?.zipCode} className="input" onChange={value => sDispatch({key: "sOrderConfig", value: {...sOrderConfig, zipCode: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("详细地址")}</p>
        <TextArea placeholder={t("详细地址")} autoSize value={sOrderConfig?.address} className="input" onChange={value => sDispatch({key: "sOrderConfig", value: {...sOrderConfig, address: value}})} />
      </div>
      <Button 
        className="next-Button"
        disabled={!sOrderConfig?.name || !sOrderConfig?.mobile || !sOrderConfig?.address || !sOrderConfig?.codeData}
        onClick={() => history.push("/managerOrder")}
      >{t("确定")}</Button>
      <Mask visible={maskModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="bg-container">
          <div className="head-container">
            <div className="empty-div"></div>
            <p className="title">{t("国家代码")}</p>
            <img src={IMAGE.close_black} alt="" className="close-button" onClick={() => setMaskModal(false)} />
          </div>
          {
            !!countryCodeData && <div className="body-container">
              <div className="body-scroll">
                <ul className="content-list">
                {
                  Object.keys(countryCodeData).map((item, index) => {
                    return <li className="section-container" key={index}>
                      <p className="section-key">{item === "#" ? t("热门") : item}</p>
                      <ul className="section-value-list">
                      {
                        countryCodeData[item].map((codeData, idx) => {
                          return <li key={idx} className="section-value-item" onClick={() => {
                            sDispatch({key: "sOrderConfig", value: {...sOrderConfig, codeData: codeData}})
                            setMaskModal(false)
                          }}>
                            <p className="value-name">{codeData.name}</p>
                            <p className="value-code">{`+${codeData.numberCode}`}</p>
                            {
                              sOrderConfig?.codeData?.name === codeData.name && <img src={IMAGE.checked} alt="" className="checked" />
                            }
                          </li>
                        })
                      }
                      </ul>
                    </li>
                  })
                }
                </ul>
              </div>
            </div>
          }
        </div>
      </Mask>
    </div>
  )
}
export default withRouter(ManagerAddressPage)

const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["name"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}