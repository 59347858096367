/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import { withRouter, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Input, Toast } from "antd-mobile"
import { post } from "@/service"
import { IMAGE } from "@/assets"
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const { number } = useParams()
  const [amount, setAmount] = useState(number || 0)
  const [timeOut, setTimeOut] = useState(0)
  const timerID = useRef()
  const [code, setCode] = useState("")

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  async function toCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: localStorage.getItem("email")
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              sendVerifyCode({
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        }) 
      }
    })
  }

  async function sendVerifyCode(result) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      result["email"] = localStorage.getItem("email")
      result["tag"] = "APPLY_QUOTA_AUTHORIZATION"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: t("成功")
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  async function onConfirm() {
    try {
      Toast.show({
        icon: "loading",
        content: t("加载中..."),
        duration: 0
      })
      await post("card/quota/authorization/apply", {
        verifyCode: code,
        authorizationTimes: amount
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t("授权成功"),
      })
      setTimeout(() => {
        history.goBack()
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  return (
    <div className="promote-quota-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p>{t("信用提升授權")}</p>
      </div>
      <div className='desc' style={{ backgroundImage: `url(${IMAGE.quotaDesc})` }}>
      {
        t("授权完成后，将不定期根据您的综合评分结果为您自动调升消费额度或临时额度")
      }
      </div>
      {/* <div className='amount_div'>
        <img src={IMAGE.flowerLeft} alt="" />
        <div>
          <p className='t1'>{t("当前授权次数")}</p>
          <p className='t2'>{number || 0}</p>
        </div>
        <img src={IMAGE.flowerRight} alt="" />
      </div> */}
      {/* <div className='operation_div'>
        <p className='name'>{t("授权次数调整至")}</p>
        <div>
          <img src={IMAGE.quatoReduce} alt="" style={{ opacity: amount === 0 ? "0.5" : "1" }} onClick={() => {
            if (Number(amount) === 0) return
            setAmount(Number(amount) - 1)
          }} />
          <p>{amount}</p>
          <img src={IMAGE.quatoAdd} alt="" onClick={() => {
            setAmount(Number(amount) + 1)
          }} />
        </div>
      </div> */}
      <p className='name'>{t("申请数字信用")}</p>
      <div className='input_div'>
        <Input placeholder={t("邮箱验证码")} value={code} className="input" onChange={value => setCode(value)}/>
        {
          timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>{t("发送")}</p> : <p className="resend-button resend-button-disable">{`${t("重新发送")} ${timeOut}s`}</p>
        }
      </div>
      <Button disabled={!code} className='confirm-button' onClick={() => onConfirm()}>{t("确认授权")}</Button>
      <p className='txt'>{t("温馨提示")}</p>
    </div>
  )
}
export default withRouter(ManagerAddressPage)