import React, {useState, useEffect, useRef} from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import { inWechat } from "@/tool"
import './styles.less' 

const VerifyAccountPage = ({ history }) => {
  const { t } = useTranslation()
  const [userData, setUserData] = useState(null)
  const [codeValue, setCodeValue] = useState("")
  const [timeOut, setTimeOut] = useState(0)
  const timerID = useRef()
  const [onFocusCode, setOnFocusCode] = useState(false)
  const [passwordValue, setPasswordValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [onFocus, setOnFocusPassword] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _userData = history.location.state
      setUserData(_userData)
      sendVerifyCode(_userData.account, {
        captchaType: _userData.captchaType,
        tencentRandstr: _userData.tencentRandstr,
        tencentTicket: _userData.tencentTicket,
        googleResponse: _userData.googleResponse
      })
    }
  // eslint-disable-next-line
  }, [])

  async function toCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: userData.account
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              sendVerifyCode(userData.account, {
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode(userData.account, {
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode(userData.account, {
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        })
      }
    })
  }

  async function sendVerifyCode(account, result) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      result["email"] = account
      result["tag"] = "SIGNUP"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: t("成功")
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  async function next() {
    const _codeValue = codeValue.trim()
    const _passwordValue = passwordValue.trim()
    const _confirmValue = confirmValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_codeValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入邮箱验证码")
      })
    } else if (!_passwordValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入你的密码")
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("请确认你的密码")
      })
    } else if (_passwordValue.length < 8) {
      Toast.show({
        icon: 'fail',
        content: t("至少 8 个字符")
      })
    } else if (!gz.test(_passwordValue)) {
      Toast.show({
        icon: 'fail',
        content: t("字母和数字的混合"),
      })
    } else if (_passwordValue !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("两次密码输入需要保持一致"),
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        await post("/app/account/emailVerify", {
          "code": _codeValue,
          "email": userData.account,
          "tag": "SIGNUP"
        })
        const resp = await post("/app/account/signUp/email", {
          "email": userData.account,
          "verify_code": _codeValue,
          "password": _passwordValue,
          "invite_code": userData.referralCode,
          "verify_code_tag": "SIGNUP"
        })
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("成功")
        })
        setTimeOut(() => {
          pushTo(resp.token)
        }, 1000)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      } 
    }
  }

  function pushTo(tokenString) {
    const inwechat = inWechat()
    if (inwechat) {
      history.push("/browser")
    } else {
      localStorage.setItem("token", tokenString)
      history.push("/")
    }
  }
  
  return (
    <>
    {
      !!userData ? <div className="verify-account-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{t("注册")}</p>
      <p className="content">{t("密码至少 8 个字符 — 字母和数字的混合")}</p>
      <p className="input-name">{t("验证你的邮箱")}</p>
      <div className={`input-container ${onFocusCode ? 'input-container-active' : ''}`}>
        <Input clearable value={codeValue} onFocus={() => setOnFocusCode(true)} onBlur={() => setOnFocusCode(false)} onChange={val => { setCodeValue(val) }} className="input-div" />
        {
          timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>{t("重新发送")}</p> : <p className="resend-button resend-button-disable">{`${t("重新发送")} ${timeOut}s`}</p>
        }
      </div>
      <p className="input-name">{t("密码")}</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input clearable value={passwordValue} type='password' onFocus={() => setOnFocusPassword(true)} onBlur={() => setOnFocusPassword(false)} onChange={val => { setPasswordValue(val) }} className="input-div" />
      </div>
      <p className="input-name">{t("确认密码")}</p>
      <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
        <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
      </div>
      <Button className="send-button" onClick={() => next()}>{t("继续")}</Button>
    </div> : <></>
    }
    </>
  )
}
export default withRouter(VerifyAccountPage)