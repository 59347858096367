import React, { useState } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Input, Button, Modal } from "antd-mobile"
import { IMAGE } from "@/assets"
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sOrderConfig } = sState
  const [visible, setVisible] = useState(false)

  return (
    <div className="manager-address-page">
      <div className="navbar">
        {
          sessionStorage.getItem("startTag") === "open" ? (
            <img src={IMAGE.home} alt="" onClick={() => setVisible(true)} />
          ) : (
            <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
          )
        }
      </div>
      <p className='title'>{t("卡信息")}</p>
      <div className="session-div">
        <p className="name">{t("卡上的名字")}</p>
        <Input placeholder={t("英文/拼音")} value={sOrderConfig?.firstName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sOrderConfig", value: {...sOrderConfig, firstName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("卡上的姓氏")}</p>
        <Input placeholder={t("英文/拼音")} value={sOrderConfig?.lastName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sOrderConfig", value: {...sOrderConfig, lastName: value}})
        }} />
      </div>
      <div className='flex1' />
      <Button 
        className="next-Button"
        disabled={!sOrderConfig?.firstName || !sOrderConfig?.lastName}
        onClick={() => history.push("/openAddress")}
      >{t("继续")}</Button>
      <Modal visible={visible} bodyClassName="m_modal_logout" content={
        <div className="modal-content-div">
          <p className="title">{t("登出账号？")}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setVisible(false)}>{t("取消")}</Button>
            <Button className="button confirm" onClick={() => {
              localStorage.setItem("token", "")
              history.push("/open")
            }}>{t("登出")}</Button>
          </div>
        </div>
      } />
    </div>
  )
}
export default withRouter(ManagerAddressPage)