import React from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Toast } from 'antd-mobile'
import { IMAGE } from "@/assets"
import { post } from "@/service"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()

  const onDeduct = async () => {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("/card/transaction/deductOpenCardFee")
      let resp
      do {
        resp = await post("/app/account/info")
        if (resp?.cardFeeStatus !== "PAID") {
          await new Promise(resolve => setTimeout(resolve, 2000))
        }
      } while (resp?.cardFeeStatus !== "PAID")
      Toast.show({
        icon: "success",
        content: t("支付成功"),
      })
      await new Promise(resolve => setTimeout(resolve, 1000))
      history.push('/apply')
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }
  
  return (
    <div className="pay-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("支付$408開卡费")}</p>
      <div className="item" style={{ "--bgcolor": "#D1840A" }} onClick={() => onDeduct()}>
        <div className="icon">
          <img src={IMAGE.pay1} alt="" />
        </div>
        <p>{t("使用USDT賬戶")}</p>
        <img src={IMAGE.arrow1} alt="" />
      </div>
      <div className="item" onClick={() => history.push("/openPaymentCode")}>
        <div className="icon"  style={{ "--bgcolor2": "#D1840A" }}>
          <img src={IMAGE.pay2} alt="" />
        </div>
        <p  style={{ "--color": "#212121" }}>{t("使用付款碼")}</p>
        <img src={IMAGE.arrow2} alt="" />
      </div>
    </div>
  )
}
export default withRouter(IndexPage)