import React from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { Button, Toast } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import './styles.less'

const ManagerOrderPage = ({history}) => {
  const { t } = useTranslation()
  const { sState } = useSessionStore()
  const { sOrderConfig } = sState

  const onConfirm = async () => {
    try {
      Toast.show({
        icon: "loading",
        content: "加载中…",
        duration: 0
      })
      await post("card/apply", {
        firstName: sOrderConfig?.firstName,
        lastName: sOrderConfig?.lastName,
        receiptName: sOrderConfig?.name,
        mobile: sOrderConfig?.codeData?.numberCode + "-" + sOrderConfig?.mobile,
        address: sOrderConfig?.address,
        asset: "USDT",
        depositAmount: "10",
        city: sOrderConfig?.city,
        zipCode: sOrderConfig?.zipCode,
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "订购成功",
      })
      setTimeout(() => {
        history.push("/")
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  return (
    <div className="manager-order-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("订购实体卡")}</p>
      <p className='subtitle'>{t("收货信息")}</p>
      <div className='info-item'>
        <img src={IMAGE.name} alt="" />
        <p>{sOrderConfig?.name}</p>
      </div>
      <div className='info-item'>
        <img src={IMAGE.mobile} alt="" />
        <p>{`+${sOrderConfig?.codeData?.numberCode || ""} ${sOrderConfig?.mobile}`}</p>
      </div>
      <div className='info-item'>
        <img src={IMAGE.address} alt="" />
        <p>{sOrderConfig?.address}</p>
      </div>
      <div className='line' />
      <p className='subtitle'>{t("邮费")}</p>
      <div className='fee-item'>
        <p>制卡费</p>
        <p>$10</p>
      </div>
      <div className='fee-item'>
        <p>邮费</p>
        <p>$0</p>
      </div>
      <div className='fee-item fee-last-item'>
        <p>总费用</p>
        <p className='bold'>$10</p>
      </div>
      <div className='line' />
      <p className='subtitle'>运送政策</p>
      <p className='desc'>货到后出货前会以电邮通知出货详情。货物运送途中如需要更改收件资料请直接联系物流公司。如没有收到邮件，请先检查垃圾箱。</p>
      <div className='flex1' />
      {/* {
        !!sOrderConfig?.hasOrder ? (
          <div className='order-result'>
            <img src={IMAGE.order} alt="" />
            <p className='t1'>已申请订购</p>
            <p className='t2'>感谢您的支持，收到实体卡后即可使用</p>
          </div>
        ) : ( */}
          <Button 
            className="next-Button"
            onClick={() => onConfirm()}
          >订购</Button>
        {/* )
      } */}
    </div>
  )
}
export default withRouter(ManagerOrderPage)