import React from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import moment from "moment"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { data } = history.location.state
  
  return (
    <div className="announcement-detail-page">
       <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p className="title">{t("公告详情")}</p>
      </div>
      {
        data.jumpType === "RICH_TEXT" ? <p dangerouslySetInnerHTML={{ __html: data.linkContent }}></p> : <div className="content-div">
          <p className="title">{ data.title }</p>
          <p className="content">{ data.content }</p>
          <p className="time">{ moment(data.time).format("YYYY-MM-DD HH:mm") }</p>
        </div>
      }
    </div>
  )
}
export default withRouter(IndexPage)