import React, { useEffect } from "react"
import { withRouter, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { Button } from 'antd-mobile'
import { inWechat } from "@/tool"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { code } = useParams()
	const inwechat = inWechat()

  const explains = [{
    number: '$408',
    text: t("开卡费")
  },{
    number: '2.5%',
    text: t("消费手续费")
  },{
    number: '3.5%',
    text: t("ATM取现")
  },{
    number: '1%',
    text: t("充值手續費")
  },{
    number: '$10,000',
    text: t("单月充值限额")
  },{
    number: '$100,000',
    text: t("单笔消费限额")
  }]

  useEffect(() => {
    sessionStorage.setItem("startTag", "open")
  }, [])
  
  return (
    <div className="p_open">
      <img src={IMAGE.cardImage} alt="" className="p_open_image" />
      <p className="p_open_title">{t("金猴卡")}</p>
      <p className="p_open_content">{ t("以美元結算，使用加密貨幣增值，可以在任何接受萬事達卡的地方消費和取款") }</p>
      <div className="p_open_list">
      {
        explains.map((item, index) => {
          return <div key={index} className="p_open_item">
            <img src={IMAGE[`card_icon_${index + 1}`]} alt="" />
            <div>
              <p className="number">{item.number}</p>
              <p className="text">{item.text}</p>
            </div>
          </div>
        })
      }
      </div>
      <Button className="p_open_register" onClick={() => {
        history.push({
          pathname: "/openResister",
          state: {
            code: !!code ? code : ""
          }
        })
      }}>{ t("开户") }</Button>
      <div className="p_open_login" onClick={() => {
        if (!inwechat) {
          history.push('/login')	
        } else {
          if (!!code && code.length > 0) {
            history.push(`/browser?code=${code}`)
          } else {
            history.push('/browser')
          }
        }
      }}>
        {t("已有账户？")}
        <span>{t("登入")}</span>
      </div>
    </div>
  )
}
export default withRouter(IndexPage)