/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { Input, Button, Toast, Popup, NumberKeyboard, Modal } from 'antd-mobile'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { post } from "@/service"
import { DelNum } from "@/tool"
import "./styles.less"

const supportEmail = "renrenpaymoney"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)
  const [visible5, setVisible5] = useState(false)
  const [amount, setAmount] = useState("")
  const [amount2, setAmount2] = useState("10")
  const [assets, setAssets] = useState([])
  const [assetIndex, setAssetIndex] = useState(0)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [openCardFee, setOpenCardFee] = useState('-')
  const [usdtPrice, setUsdtPrice] = useState('-')
  const [emailVisible, setEmailVisible] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("card/rules")
        Toast.clear()
        setOpenCardFee(resp.openCardFee)
        setUsdtPrice(resp.usdtPrice)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  async function applyCard() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      await post("/card/apply", {
        "firstName": firstName,
        "lastName": secondName,
        "depositAmount": `${Math.ceil(parseFloat(menuIndex < 4 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100}`,
        "asset": assets[assetIndex].asset
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: t("申请开卡成功"),
      })
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  useEffect(() => {
    async function getAssets() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/info")
        const accounts = resp.accounts
        const AccountUSD = accounts.find(account => account.asset === "USD")
        AccountUSD.openCardFee = 5
        AccountUSD.minRecharge = 10
        AccountUSD.price = 1
        const AccountUSDT = accounts.find(account => account.asset === "USDT")
        AccountUSDT.openCardFee = Math.ceil(5 / parseFloat(usdtPrice) * 100) / 100
        AccountUSDT.minRecharge = Math.ceil(10 / parseFloat(usdtPrice) * 100) / 100
        AccountUSDT.price = parseFloat(usdtPrice)
        const _assets = [AccountUSDT]
        setAssets(_assets)
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    if (visible) {
      getAssets()
    } else {
      getAssets()
      if (openCardFee !== '-' && parseFloat(openCardFee) === 0) {
        setAmount("10")
      } else {
        setAmount("10")
        setAmount2("10")
        setMenuIndex(0)
      }
      setKeyboardVisible(false)
      setAssetIndex(0)
    }
  }, [visible, openCardFee, usdtPrice])

  function countDecimalPlaces() {
    const decimalRegex = /\.(\d+)/
    const match = decimalRegex.exec(amount)
    if (match) {
      return match[1].length
    } else {
      return 0
    }
  }

  return (
    <div className="card-confirm-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className="title">{t("确认您的信息")}</p>
      <div className="session-div">
        <p className="name">{t("卡上的名字")}</p>
        <Input placeholder={t("英文/拼音")} value={firstName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          setFirstName(value)
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("卡上的姓氏")}</p>
        <Input placeholder={t("英文/拼音")} value={secondName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          setSecondName(value)
        }} />
      </div>
      <Button className="confirm-button" disabled={!firstName || !secondName} onClick={() => setVisible3(true)}>{t("下一步")}</Button>
      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyClassName="add-founds-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible(false)} />
        <p className="title">{t("充值")}</p>
        <p className="content">{`${t("开卡费")}5USDT， ${t("并充值")}${menuIndex < 4 ? amount2 : amount === '' ? '-' : amount}USD`}</p>
        <div className="menu-list">
        {
          ['10', '100', '500', '1000', ''].map((item, index) => {
            return <div key={index} className="menu-item" style={{ borderColor: menuIndex === index ? '#D1840A' : 'transparent' }} onClick={() => {
              setMenuIndex(index)
              if (index < 4) {
                setAmount2(item)
                setKeyboardVisible(false)
              } else {
                setKeyboardVisible(true)
              }
            }}>
              {
                item !== '' ? <>
                  <p className="t1">{`${item}USD`}</p>
                  <p className="t2">{`${item}USDT`}</p>
                </> : <>
                {
                  menuIndex === index ? <p className="amount">{ `${amount}` }<span style={{ visibility: keyboardVisible ? 'visible' : 'hidden' }}></span></p> : <p className="placehorder-txt">{t("自定义金额")}</p>
                }
                </>
              }
            </div>
          })
        }
        </div>
        {/* <p className="subtitle">请输入充值金额</p>
        <div className="input-div">
          <p className="amount-div" onClick={() => setKeyboardVisible(true)}>{ `$${amount}` }<span style={{ visibility: keyboardVisible ? 'visible' : 'hidden' }}></span></p>
          {
            assets.length > 0 && <p className="max-button" onClick={() => {
              if (openCardFee !== '-' && parseFloat(openCardFee) === 0) {
                const _balance = Math.max(assets[assetIndex].balance - 0.01, 0)
                const _max = Math.floor(_balance * parseFloat(assets[assetIndex].price) * 100) / 100 
                setAmount(_max.toString())
              } else {
                const _balance = Math.max(assets[assetIndex].balance - assets[assetIndex]?.minRecharge - 0.01, 0)
                const _max = Math.floor(_balance * parseFloat(assets[assetIndex].price) * 100) / 100 
                setAmount(_max.toString())
              }
            }}>全部</p>
          }
        </div>
        {
          assets.length > 0 && <p className="content">{ openCardFee !== '-' && parseFloat(openCardFee) === 0 ? `最小充值金额: ${assets[assetIndex]?.minRecharge * 2}${assets[assetIndex].asset}。` : `开卡费: ${assets[assetIndex].openCardFee}${assets[assetIndex].asset}，最小充值金额: ${assets[assetIndex]?.minRecharge}${assets[assetIndex].asset}` }</p>
        } */}
        {
          assets.length > 0 && <div className="pay-div" onClick={() => {
            setKeyboardVisible(false)
          }}>
            <img src={assets[assetIndex].token.icon} alt="" className="img1" />
            <div style={{ flex: '1' }}>
              <p className="t1">{t("支付币种")}</p>
              <p className="t2" style={{marginTop: '2px'}}>{ `${Math.ceil(parseFloat(menuIndex < 4 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100 + (openCardFee !== '-' && parseFloat(openCardFee) === 0 ? 0 : assets[assetIndex].openCardFee)} ${assets[assetIndex].asset}` }</p>
            </div>
            {
              <div style={{alignItems: "flex-end"}}>
                <p className="t1">{t("余额")}</p>
                <p className="t1" style={{marginTop: '4px'}}>{ DelNum(assets[assetIndex].balance) }</p>
              </div>
            }
            {/* <img src={IMAGE.clickTag} alt="" className="img2" /> */}
          </div>
        }
        {
          assets.length > 0 && <Button className="confirm-button" disabled={
            (menuIndex === 5 && !amount) || 
            Math.ceil(parseFloat(menuIndex < 4 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100 < assets[assetIndex]?.minRecharge * (openCardFee !== '-' && parseFloat(openCardFee) === 0 ? 2 : 1) || 
            assets[assetIndex].balance - Math.ceil(parseFloat(menuIndex < 4 ? amount2 : amount === '' ? '0' : amount) / parseFloat(assets[assetIndex].price) * 100) / 100 - parseFloat(openCardFee === '-' ? '0' : openCardFee) < 0
          } onClick={() => applyCard()}>{`${t("开卡并充值")}${menuIndex < 4 ? amount2 : amount === '' ? '-' : amount}USDT`}</Button>
        }
        {/* {
          assets.length > 0 && <p className="question-button" onClick={() => setEmailVisible(true)}>{t("遇到问题?")}</p>
        } */}
      </Popup>
      <Popup
        visible={visible2}
        onMaskClick={() => setVisible2(false)}
        bodyClassName="add-founds-token-popup"
      >
        <div className="token-list">
        {
          assets.map((item, index) => {
            return <div className="token-item" key={index} onClick={() => {
              setAssetIndex(index)
              if (openCardFee !== '-' && parseFloat(openCardFee) === 0) {
                setAmount('20')
              } else {
                setAmount('10')
              }
              setVisible2(false)
            }}>
              <img src={item.token.icon} alt="" className="icon" />
              <p className="name">{ item.asset }</p>
              {
                assetIndex === index && <img src={IMAGE.select_icon} alt="" className="select-icon" />
              }
            </div>
          })
        }
        </div>
      </Popup>
      <Popup
        visible={visible3}
        onMaskClick={() => setVisible3(false)}
        bodyClassName="open-card-way-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible3(false)} />
        <p className="title">{t("开卡方式")}</p>
        <div className="way-item item1" onClick={() => {
          setVisible3(false)
          if (assets[assetIndex].balance < 10) {
            setVisible4(true)
          } else {
            setVisible(true)
          }
        }}>
          <img src={IMAGE.usdt} alt="" className="icon" />
          <div className="txt-div">
            <p className="t1">{t("使用USDT快速开卡")} <span>{t("推荐")}</span> </p>
            <p className="t2">{t("开卡费: 5USDT, 并充值10USDT")}</p>
          </div>
          <img src={IMAGE.clickTag} alt="" className="tag" />
        </div>
        {/* <div className="way-item item2" onClick={() => {
          setVisible3(false)
          setVisible5(true)
        }}>
          <img src={IMAGE.customer2} alt="" className="icon" />
          <div className="txt-div">
            <p className="t1">人工通道开卡</p>
            <p className="t2">适合新手小白，手把手指导开卡</p>
          </div>
          <img src={IMAGE.clickTag} alt="" className="tag" />
        </div> */}
      </Popup>
      <Popup
        visible={visible5}
        onMaskClick={() => setVisible5(false)}
        bodyClassName="customer-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible5(false)} />
        <p className="title">{t("开卡")}</p>
        <p className="content">{t("添加客服微信指导开卡操作")}</p>
        <img src={IMAGE.customerCode} alt="" className="code" />
        <p className="t1">{t("扫一扫上面的二维码图案，加我为朋友")}</p>
      </Popup>
      <NumberKeyboard
        visible={keyboardVisible}
        onClose={() => setKeyboardVisible(false)}
        onInput={(value) => setAmount(v => countDecimalPlaces() < 3 && amount.length < 4 ? v + value : v)}
        onDelete={() => setAmount(v => v.slice(0, v.length - 1))}
        confirmText={t("完成")}
        customKey={amount.includes(".") ? [] : ['.']}
      />
      <Modal visible={emailVisible} bodyClassName="card-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("联系我们")}</p>
          <p className="content">{`${t("微信号：")}${supportEmail}`}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setEmailVisible(false)}>{t("取消")}</Button>
            <CopyToClipboard text={supportEmail} onCopy={() => {
              setEmailVisible(false)
              Toast.show({
                icon: "success",
                content: t("复制成功"),
              })
            }}>
              <Button className="button confirm">{t("复制微信号")}</Button>
            </CopyToClipboard>
          </div>
        </div>
      } />
      <Modal visible={visible4} bodyClassName="card-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("USDT余额不足")}</p>
          <p className="content">{t("您的 USDT 余额低于10, 建议先充值")}</p>
          <div className="buttons-div">
            {/* <Button className="button cancle" onClick={() => {
              setVisible4(false)
              setVisible5(true)
            }}>{t("人工通道开卡")}</Button> */}
            <Button className="button confirm" onClick={() => history.push("/recharge")}>{t("充值USDT")}</Button>
          </div>
        </div>
      } />
    </div>
  )
}
export default withRouter(IndexPage)