/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Toast } from "antd-mobile"
import { DelNum } from "@/tool"
import { IMAGE } from "@/assets"
import { get } from "@/service"
// import moment from 'moment'
import './styles.less'

const ManagerAddressPage = ({history}) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await get("card/quota")
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  return (
    <div className="manager-quota-page">
      <div className="navbar">
        <img src={IMAGE.back_white} alt="" onClick={() => history.goBack()} />
        <p>{t("数字信用管理")}</p>
      </div>
      <div className='content'>
        <p className="title">{t("可用额度")}</p>
        <p className='amount'>{`$${!!data ? DelNum(data.balance) : "-"}`}</p>
        <div className='content_row'>
          <div className='content_row_item'>
            <p className='t1'>{t("数字信用")}</p>
            <p className='t2'>{`$${!!data ? DelNum(data.consumeQuota) : "-"}`}</p>
            <Button className='button' onClick={() => history.push(`/promoteQuota/${data?.authorizationTimes || 0}`)}>{t("提升信用")}</Button>
            <p className='t3'>{`${t("最高可达")}$${!!data ? DelNum(data.maxConsumeQuota) : "-"}`}</p>
          </div>
          <div className='content_row_item'>
            <p className='t1'>{t("临时额度")}</p>
            <p className='t2'>{t("暂无临额")}</p>
            <Button className='button' disabled>{t("暂未开放")}</Button>
            <p className='t3'>{t("敬请期待")}</p>
          </div>
        </div>
      </div>
      <Button className='promote-button' onClick={() => history.push(`/promoteQuota/${data?.authorizationTimes || 0}`)}><img src={IMAGE.arrowTop} alt="" />{t("信用提升授權")}</Button>
      {/* <div className='list'>
        <div className='head'>
          <img src={IMAGE.line1} alt='' />
          <p>{t("额度记录")}</p>
          <img src={IMAGE.line2} alt='' />
        </div>
        <div className='datas'>
        {
          [1,2,3].map((item, index) => {
            return (
              <div className='item' key={index}>
                <img src={index === 0 ? IMAGE.quotaBottom : IMAGE.quotaTop} alt="" className='icon' />
                <div className='item_div'>
                  <div className='item_row'>
                    <p className='t1'>{index === 0 ? t("额度下降") : t("额度提升")}</p>
                    <p className='t2'>{`${index === 0 ? "-" : "+"}$${DelNum(1000)}`}</p>
                  </div>
                  <div className='item_row'>
                    <p className='t3'>{moment().format("YYYY-MM-DD HH:mm")}</p>
                    <p className='t3'>{index === 0 ? t("审核中") : index === 1 ? t("已拒绝") : ""}</p>
                  </div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div> */}
    </div>
  )
}
export default withRouter(ManagerAddressPage)