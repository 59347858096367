import React, {useState, useRef} from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import './styles.less' 

const ChangePasswordPage = ({ history }) => {
  const { t } = useTranslation()
  const [onFocusCurrent, setOnFocusCurrent] = useState(false)
  const [currentValue, setCurrentValue] = useState("")
  const [passwordValue, setPasswordValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [onFocus, setOnFocusPassword] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)
  const [onFocusCode, setOnFocusCode] = useState(false)
  const [codeValue, setCodeValue] = useState("")
  const [timeOut, setTimeOut] = useState(0)
  const timerID = useRef()

  async function toCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: localStorage.getItem("email")
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfAEO8pAAAAABS3dzHsQXOEe7up3b-tHoSDQbPx', {action: 'submit'}).then(function(token) {
              sendVerifyCode({
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function initTencentCaptcha(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        }) 
      }
    })
  }

  async function sendVerifyCode(result) {
    try {
      Toast.show({
        icon: 'loading',
        content: t("加载中..."),
        duration: 0
      })
      result["email"] = localStorage.getItem("email")
      result["tag"] = "RESET_PASSWORD"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: t("成功")
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }
  
  async function next() {
    const _currentValue = currentValue.trim()
    const _passwordValue = passwordValue.trim()
    const _confirmValue = confirmValue.trim()
    const _codeValue = codeValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_currentValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入当前密码")
      })
    } else if (!_passwordValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入新密码")
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("请确认你的密码")
      })
    } else if (_passwordValue.length < 8) {
      Toast.show({
        icon: 'fail',
        content: t("至少 8 个字符")
      })
    } else if (!gz.test(_passwordValue)) {
      Toast.show({
        icon: 'fail',
        content: t("字母和数字的混合"),
      })
    } else if (_passwordValue !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("两次密码输入需要保持一致"),
      })
    } else if (!_codeValue) {
      Toast.show({
        icon: 'fail',
        content: t("请输入邮箱验证码"),
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        await post("/app/account/resetPassword", {
          "old_password": _currentValue,
          "new_password": _passwordValue,
          "verify_code": _codeValue
        })
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("成功")
        })
        setTimeout(() => {
          history.goBack()
        }, 1000)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      } 
    }
  }
  
  return (
    <>
    {
      <div className="verify-account-page">
        <div className="nav-bar" >
          <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
        </div>
        <p className="title">{t("修改密码")}</p>
        <p className="content">{t("密码至少 8 个字符 — 字母和数字的混合")}</p>
        <p className="input-name">{t("当前密码")}</p>
        <div className={`input-container ${onFocusCurrent ? 'input-container-active' : ''}`}>
          <Input clearable value={currentValue} type='password' onFocus={() => setOnFocusCurrent(true)} onBlur={() => setOnFocusCurrent(false)} onChange={val => { setCurrentValue(val) }} className="input-div" />
        </div>
        <p className="input-name">{t("新密码")}</p>
        <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
          <Input clearable value={passwordValue} type='password' onFocus={() => setOnFocusPassword(true)} onBlur={() => setOnFocusPassword(false)} onChange={val => { setPasswordValue(val) }} className="input-div" />
        </div>
        <p className="input-name">{t("确认密码")}</p>
        <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
          <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
        </div>
        <p className="input-name">{t("邮箱验证码")}</p>
        <div className={`input-container ${onFocusCode ? 'input-container-active' : ''}`}>
          <Input clearable value={codeValue} onFocus={() => setOnFocusCode(true)} onBlur={() => setOnFocusCode(false)} onChange={val => { setCodeValue(val) }} className="input-div" />
          {
            timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>{t("发送")}</p> : <p className="resend-button resend-button-disable">{`${t("重新发送")} ${timeOut}s`}</p>
          }
        </div>
        <Button className="send-button" onClick={() => next()}>{t("确认")}</Button>
        {/* <p className='forget-button' onClick={() => history.replace("/forgetPassword")}>{t("忘记密码？")}</p> */}
      </div>
    }
    </>
  )
}
export default withRouter(ChangePasswordPage)