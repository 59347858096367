/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tabbar } from "@/component"
import { Modal, Button, Toast } from 'antd-mobile'
import { post } from "@/service"
import "./styles.less"

const supportEmail = "renrenpaymoney"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [emailVisible, setEmailVisible] = useState(false)
  const [logoutVisible, setLogoutVisible] = useState(false)
  const [data, setData] = useState(null)
  const [cardId, setCardId] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/app/account/info")
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function getData() {
      try {
        const resp = await post("/card/applyOrder")
        if (!!resp && resp.length > 0 && resp[0].status === 'PASSED') {
          setCardId(resp[0].cardId)
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="mine-page">
      <p className="title">{t("我的")}</p>
      <div className="sessin-div">
        <p className="key">{t("邮箱")}</p>
        <p className="value">{ data?.email }</p>
      </div>
      <div className="sessin-div" onClick={() => history.push("/changePassword")}>
        <p className="key">{t("修改密码")}</p>
        <img src={IMAGE.clickTag} alt="" className="tag" />
      </div>
      {
        !!cardId && (
          <div className="sessin-div" onClick={() => history.push(`/managerPin/${cardId}`)}>
            <p className="key">{t("修改PIN")}</p>
            <img src={IMAGE.clickTag} alt="" className="tag" />
          </div>
        )
      }
      {
        !!cardId && (
          <div className="sessin-div" onClick={() => history.push("managerQuota")}>
            <p className="key">{t("数字信用管理")}</p>
            <img src={IMAGE.clickTag} alt="" className="tag" />
          </div>
        )
      }
      <div className="sessin-div" onClick={() => history.push("/mineToken")}>
        <p className="key">{t("币种")}</p>
        <p className="value">{ localStorage.getItem("selectToken") || "USD" }</p>
        <img src={IMAGE.clickTag} alt="" className="tag" />
      </div>
      <div className="sessin-div" onClick={() => window.open(`http://service.hkmonkey.vip/chat/mobile?noCanClose=1&token=89943791c5cd8562700c40ab4f42f199&uid=${data?.id}&nickName=${data?.email}`)}>
        <p className="key">{t("联系我们")}</p>
        <img src={IMAGE.clickTag} alt="" className="tag" />
      </div>
      <Button className="logout-button" onClick={() => setLogoutVisible(true)}>{t("退出登录")}</Button>
      <Modal visible={emailVisible} bodyClassName="mine-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("联系我们")}</p>
          <p className="content">{`${t("微信号：")}${supportEmail}`}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setEmailVisible(false)}>{t("取消")}</Button>
            <CopyToClipboard text={supportEmail} onCopy={() => {
              setEmailVisible(false)
              Toast.show({
                icon: "success",
                content: t("复制成功"),
              })
            }}>
              <Button className="button confirm">{t("复制微信号")}</Button>
            </CopyToClipboard>
          </div>
        </div>
      } />
      <Modal visible={logoutVisible} bodyClassName="mine-modal" content={
        <div className="modal-content-div">
          <p className="title">{t("退出登录")}</p>
          <p className="content">{t("确定退出登录吗")}</p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setLogoutVisible(false)}>{t("取消")}</Button>
            <Button className="button confirm" onClick={() => {
              localStorage.removeItem("token")
              setLogoutVisible(false)
              history.push("/join")
            }}>{t("确定")}</Button>
          </div>
        </div>
      } />
      <Tabbar selIndex={3} />
    </div>
  )
}
export default withRouter(IndexPage)