import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const _token = localStorage.getItem("selectToken") 
  const [selectToken, setSelectToken] = useState(_token)
  
  return (
    <div className="mine-token-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className='title'>{t("选择币种")}</p>
      <div className="section-item" onClick={() => {
        localStorage.setItem("selectToken", "USD")
        setSelectToken("USD")
      }}>
        <p>USD</p>
        {
          selectToken !== "HKD" && (
            <img src={IMAGE.checked} alt="" />
          )
        }
      </div>
      <div className="section-item" onClick={() => {
        localStorage.setItem("selectToken", "HKD")
        setSelectToken("HKD")
      }}>
        <p>HKD</p>
        {
          selectToken === "HKD" && (
            <img src={IMAGE.checked} alt="" />
          )
        }
      </div>
    </div>
  )
}
export default withRouter(IndexPage)