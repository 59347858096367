import React from 'react'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Input, TextArea, Button } from "antd-mobile"
import { useSessionStore } from "@/store"
import { IMAGE } from "@/assets"
import './styles.less'

const ApplyInfoPage = ({history}) => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { sApplyConfig } = sState

  const handleBlur = () => {
    // 完整日期验证
    const fullRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/
    if (!fullRegex.test(sApplyConfig?.date) && !!sApplyConfig?.date) {
      sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: true}})
    } else {
      sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: false}})
    }
}

  return (
    <div className="apply-info-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <div className="process">
          <div className="line active" />
          <div className="line active" />
          <div className="line" />
        </div>
      </div>
      <p className='title'>{t("您的个人信息")}</p>
      <div className="session-div">
        <p className="name">{t("姓氏")}</p>
        <Input placeholder={t("拼音或者英文")} value={sApplyConfig?.lastName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, lastName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("名字")}</p>
        <Input placeholder={t("拼音或者英文")} value={sApplyConfig?.firstName} className="input" onChange={value => {
          const regex = /^[A-Za-z]*$/
          if (!regex.test(value)) return
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, firstName: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("出生日期")}</p>
        <Input placeholder="MM / DD / YYYY" value={sApplyConfig?.date} className={`input ${sApplyConfig?.errorDate ? "input-error" : ""}`} onBlur={handleBlur} onChange={value => {
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, errorDate: true}})
          sDispatch({key: "sApplyConfig", value: {...sApplyConfig, date: value}})
        }} />
      </div>
      <div className="session-div">
        <p className="name">{t("手机号")}</p>
        <Input placeholder={t("手机号")} value={sApplyConfig?.mobile} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, mobile: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("身份证号")}</p>
        <Input placeholder={t("身份证号")} value={sApplyConfig?.passportNumber} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, passportNumber: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("城市")}</p>
        <Input placeholder={t("中文")} value={sApplyConfig?.city} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, city: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("居住地址")}</p>
        <TextArea placeholder={t("中文")} autoSize value={sApplyConfig?.residentialAddress} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, residentialAddress: value}})} />
      </div>
      <div className="session-div">
        <p className="name">{t("邮编")}</p>
        <Input placeholder={t("邮编")} value={sApplyConfig?.zipCode} className="input" onChange={value => sDispatch({key: "sApplyConfig", value: {...sApplyConfig, zipCode: value}})} />
      </div>
      <Button 
        className="next-Button"
        disabled={!sApplyConfig?.lastName || !sApplyConfig?.firstName || !sApplyConfig?.date || sApplyConfig?.errorDate || !sApplyConfig?.mobile || !sApplyConfig?.passportNumber || !sApplyConfig?.city || !sApplyConfig?.residentialAddress || !sApplyConfig?.zipCode}
        onClick={() => history.push("/applyUpload")}
      >{t("继续")}</Button>
    </div>
  )
}
export default withRouter(ApplyInfoPage)