import React, { useEffect } from 'react'
import { withRouter, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Toast } from 'antd-mobile'
import { IMAGE } from "@/assets"
import { post } from "@/service"
import { inWechat } from "@/tool"
import './styles.less'

const JoinPage = ({history}) => {
	const { t } = useTranslation()
  const { code } = useParams()
	const inwechat = inWechat()

	useEffect(() => {
		async function getData() {
			try {
				Toast.show({
					icon: 'loading',
					content: t("加载中..."),
					duration: 0
				})
				await post("/app/account/info")
				Toast.clear()
				history.push("/")
			} catch (error) {
				Toast.clear()
			}
		}
		if (!inwechat) {
			getData()	
		}
	// eslint-disable-next-line
  }, [inwechat])

    return (
      <div className="join-page">
				<img src={IMAGE.join_image} alt="" className="bg-image"/>
				<div className="body-container">
					<p className="title">{t("美元万事达卡 · Mastercard")}</p>
					<div className="content">
						{t("数字信用 数字财库 数字资产")} <br /> 
						{t("资产数字化全球通行")}
						</div>
					<Button className="register-button" onClick={() => {
							history.push({
								pathname: "/register",
								state: {
									code: !!code ? code : ""
								}
							})
						}}>{t("注册")}</Button>
					<Button className="login-button" onClick={() => {
						if (!inwechat) {
							history.push('/login')	
						} else {
							if (!!code && code.length > 0) {
								history.push(`/browser?code=${code}`)
							} else {
								history.push('/browser')
							}
						}
					}}>{t("登录")}</Button>
				</div>
      </div>
    )
}
export default withRouter(JoinPage)