/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Toast } from 'antd-mobile'
import { post } from "@/service"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  
  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("加载中..."),
          duration: 0
        })
        const resp = await post("/card/status")
        Toast.clear()
        setData(resp[0].info)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error
        })
      }
    }
    getData()
  }, [])

  return (
    <div className="card-info-page">
      <div className="navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className="title">
        {t("卡信息")}
        {
          !!data && <CopyToClipboard text={`${t("支付平台")}：${data.paymentPlatform}\n${t("卡号")}:${data.number}\n${t("有效期限")}:${data.expiration}\nCVC:${data.cvv}\n${t("持卡人姓名")}:${data.name}\n${t("国家")}:${data.country}\n${t("账单地址")}:${data.residentialAddress}\n${t("城市")}:${data.city}\n${t("州")}:${data.state}\n${t("邮政编码")}:${data.zipCode}`} onCopy={() => Toast.show({
            icon: "success",
            content: t("复制成功"),
          })}>
            <p className="copy-button">{t("复制全部信息")}</p>
          </CopyToClipboard>
        }
      </p>
      {
        !!data && <>
          <div className="session-div">
            <p className="key">{t("支付平台")}</p>
            <div className="value-div">
              <p className="value">{ data.paymentPlatform }</p>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("卡号")}</p>
            <div className="value-div">
              <p className="value">{ data.number }</p>
              <CopyToClipboard text={ data.number } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("有效期限")}</p>
            <div className="value-div">
              <p className="value">{ data.expiration }</p>
              <CopyToClipboard text={ data.expiration } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">CVC</p>
            <div className="value-div">
              <p className="value">{ data.cvv }</p>
              <CopyToClipboard text={ data.cvv } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("持卡人姓名")}</p>
            <div className="value-div">
              <p className="value">{ data.name }</p>
              <CopyToClipboard text={ data.name } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("国家")}</p>
            <div className="value-div">
              <p className="value">{ data.country }</p>
              <CopyToClipboard text={ data.country } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("账单地址")}</p>
            <div className="value-div">
              <p className="value">{ data.residentialAddress }</p>
              <CopyToClipboard text={ data.residentialAddress } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("城市")}</p>
            <div className="value-div">
              <p className="value">{ data.city }</p>
              <CopyToClipboard text={ data.city } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("州")}</p>
            <div className="value-div">
              <p className="value">{ data.state }</p>
              <CopyToClipboard text={ data.state } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="session-div">
            <p className="key">{t("邮政编码")}</p>
            <div className="value-div">
              <p className="value">{ data.zipCode }</p>
              <CopyToClipboard text={ data.zipCode } onCopy={() => Toast.show({
                icon: "success",
                content: t("复制成功"),
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
        </>
      }
    </div>
  )
}
export default withRouter(IndexPage)